/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

jQuery.fn.isAfter = function(sel) {
    return this.prevAll(sel).length !== 0;
};
jQuery.fn.isBefore = function(sel) {
    return this.nextAll(sel).length !== 0;
};

function isMDwidth() {
    return jQuery('#md-indicator').is(':visible');
}

function isXSwidth() {
    return jQuery('#xs-indicator').is(':visible');
}

function swapElement() {
  if(isMDwidth()) {
    if(jQuery('.swap2').isAfter('.swap1')){
      jQuery('.swap2').each(function(i, el) {
          jQuery(el).insertBefore(jQuery(el).prev());
      });
    }
  }
  else {
    if(jQuery('.swap1').isAfter('.swap2')){
      jQuery('.swap1').each(function(i, el) {
          jQuery(el).insertBefore(jQuery(el).prev());
      });
    }
  }
}

function mobileMenu() {
  if(isXSwidth()) {
    if(jQuery('#hamburger').hasClass('active')) {
      jQuery('#header-bottom').slideUp();
      jQuery('#hamburger').removeClass('active');
    }
    else {
      jQuery('#header-bottom').slideDown();
      jQuery('#hamburger').addClass('active');
    }

    if(!jQuery('.downarrow').length) {
      jQuery('#header-bottom .menu-item-has-children').each(function() {
        var menuID = jQuery(this).attr('id');
        jQuery('#header-bottom #' + menuID).prepend('<i data-menuid="' + menuID + '" class="fa fa-chevron-down downarrow" aria-hidden="true"></i>');
      });
    }
  }
}

swapElement();

jQuery(window).resize(function() {
  swapElement();
  if(!isXSwidth()) {
    jQuery('#header-bottom').show();
    jQuery('.downarrow').remove();
  }
});

jQuery("#hamburger").click(function() {
  mobileMenu();
});

jQuery(document).on('click', '.downarrow', function(){ 
  menuid = jQuery(this).data('menuid');
  submenu = jQuery('#header-bottom #' + menuid + ' .sub-menu');
  if(jQuery(this).hasClass('active')) {
    submenu.slideUp();
    submenu.removeClass('active');
    jQuery(this).removeClass('active');
  }
  else {
    jQuery('#header-bottom .sub-menu').slideUp();
    jQuery('#header-bottom .sub-menu').removeClass('active');
    jQuery('#header-bottom .downarrow').removeClass('active');
    submenu.slideDown();
    submenu.addClass('active');
    jQuery(this).addClass('active');
  }
});

jQuery("#searchicon").click(function() {
  if(jQuery("#searchcontainer").hasClass('active')) {
    if(jQuery("#searchform input").val().length !== 0) {
      jQuery("#searchform").submit();
    }
    else {
      jQuery("#searchcontainer").removeClass('active');
      jQuery("#searchform input").blur();
    }
  }
  else {
    jQuery("#searchcontainer").addClass('active');
    jQuery("#searchform input").focus();
  }
});

jQuery(function() {
    jQuery(window).scroll(function() {
        var scroll = jQuery(window).scrollTop();
        if (scroll >= 150) {
            jQuery("#header").addClass('smaller');
        } else {
            jQuery("#header").removeClass('smaller');
        }
    });
});